@import "/fonts/Exo2/stylesheet.css";
@import "/fonts/Montserrat/stylesheet.css";
* {
  font-family: Montserrat;
}
.my_container {
  width: 1160px;
  margin: auto;
}
.width_100 {
  width: 100%;
}
.buttons_a {
  display: flex;
  flex-wrap: wrap;
}
.buttons_a a {
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 56px;
  border-radius: 8px;
}
.buttons_a a:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  transition: 0.3s;
}
.buttons_a .orange_a {
  background: #EB6D40;
  color: #ffffff;
}
.buttons_a .orange_a:hover {
  background: rgba(235, 109, 64, 0.8);
}
h1 {
  font-size: 55px;
  line-height: 66px;
  color: #ffffff;
  font-family: "Exo";
  padding-bottom: 56px;
}
h1 span {
  color: #EB6D40;
}
h2 {
  color: #ffffff;
  font-size: 55px;
  line-height: 66px;
}
h2 span {
  color: #EB6D40;
}
h3 {
  color: #ffffff;
  font-size: 30px;
  font-family: "Exo";
}
h3 span {
  color: #EB6D40;
}
p {
  color: #ffffff;
  font-size: 20px;
}
.wave_div {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
}
.plx_bg_div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
header {
  background: #045F9D;
  padding-top: 48px;
  padding-bottom: 120px;
  position: relative;
}
header a {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
}
header a:hover {
  color: #ffffff;
}
header .plx_bg_div {
  background: url('../img/header_circles.svg') no-repeat center;
  background-size: contain;
}
header .border_a {
  position: relative;
  padding-bottom: 10px;
}
header .border_a:after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 10px;
  background: url('../img/border_header.svg') no-repeat;
  background-size: contain;
}
header .buttons_a {
  margin-top: 45px;
}
header .title_img {
  width: 100%;
  width: calc(1160px/12 * 5);
}
header .info_row {
  padding: 80px 0px;
}
.protivopokazaniya .plashka {
  height: 79px;
  width: 100%;
  background: #1B6699;
  position: fixed;
  bottom: 0px;
  left: 0;
  text-transform: uppercase;
  padding-top: 10px;
  opacity: 1;
  z-index: 10;
}
.protivopokazaniya .plashka p {
  font-family: Exo;
  font-size: 46px;
  line-height: 30px;
  letter-spacing: 14px;
  color: #ffffff;
  position: relative;
}
.protivopokazaniya .plashka p span {
  font-size: 24px;
  letter-spacing: 8px;
  font-family: Exo;
  text-transform: uppercase;
}
.protivopokazaniya .plashka p img {
  position: absolute;
  right: -14px;
  top: -69px;
}
.section_1 {
  padding: 120px 0px 204px 0px;
  position: relative;
  overflow: hidden;
  background: #1b588f;
}
.section_1 .plx_bg_div {
  background: url('../img/section_1_plx.svg') no-repeat center;
  background-size: contain;
  z-index: 0;
}
.section_1 h2 {
  margin-bottom: 130px;
}
.section_1 .numbers_abs {
  width: 105px;
  height: 105px;
  position: relative;
}
.section_1 .numbers_abs .circle {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  opacity: 0.5s;
  background: #1B6699;
}
.section_1 .numbers_abs .number {
  font-size: 123px;
  min-width: 64px;
  color: #ffffff;
  position: absolute;
  right: -14px;
  top: -72px;
  z-index: 1;
  font-family: Exo;
}
.section_2 {
  padding: 105px 0px;
  background: #045F9D;
  position: relative;
}
.section_2 .my_container {
  overflow: hidden;
}
.section_2 .overflow_hidden {
  position: relative;
  min-height: 800px;
  overflow: hidden;
}
.section_2 .plx_bg_div {
  background: url('../img/section_2_plx.svg') no-repeat center;
  background-size: contain;
  z-index: 0;
}
.section_2 h2 {
  margin-bottom: 112px;
  padding-top: 20px;
}
.section_2 .factors_row_1 {
  /*position: relative;*/
  position: absolute;
  /*background: @bright_blue;*/
  z-index: 0;
  top: 0;
}
.section_2 .factors_row_2 {
  position: absolute;
}
.section_2 .factor_riska {
  /*margin-bottom: 172px;*/
  /*background: @bright_blue;*/
  /*		&:nth-child(n+4) {
			display: none;
		}*/
}
.section_2 .factor_riska .p_title {
  font-size: 30px;
  font-family: Exo;
  line-height: 36px;
  min-height: 116px;
}
.section_2 .factor_riska .p_title span {
  font-size: 20px;
  font-family: Montserrat;
}
.section_2 .factor_riska .just_text {
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  opacity: 0.7;
}
.section_2 .factor_riska .icon_div {
  min-height: 198px;
  position: relative;
}
.section_2 .factor_riska .icon_div img {
  position: relative;
  z-index: 1;
}
.section_2 .factor_riska .icon_div:before {
  content: '';
  width: 148px;
  height: 148px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: #1B6699;
  z-index: 0;
}
.test_sprite {
  background-image: url('../img/sprite_1.png');
  height: 500px;
  width: 500px;
}
#phaser-example {
  display: flex;
}
@media (max-width: 1440px) {
  h2 {
    font-size: 44px;
    line-height: 50px;
  }
  .section_2 h2 {
    margin-bottom: 41px;
  }
}
@media (max-width: 1024px) {
  .my-container {
    width: 900px;
  }
}
@media (max-width: 525px) {
  h1 {
    font-size: 26px;
    line-height: 32px;
  }
  h2 {
    font-size: 26px;
    line-height: 32px;
  }
  p {
    font-size: 16px;
  }
  .my_container,
  .my-container {
    width: 315px;
  }
  header {
    padding-bottom: 46px;
    /*		.menu_header a{
			display: none;
		}*/
  }
  header h1 {
    text-align: center;
  }
  header .title_img {
    width: 100%;
  }
  header .info_row {
    padding: 20px 0px;
  }
  header .buttons_a a {
    margin-bottom: 30px;
  }
  .protivopokazaniya .my_container {
    width: 100%;
  }
  .protivopokazaniya .plashka {
    padding-top: 25px;
    bottom: 0px;
  }
  .protivopokazaniya .plashka p {
    line-height: 18px;
    font-size: 11px;
    letter-spacing: 2px;
  }
  .protivopokazaniya .plashka p span {
    font-size: 11px;
    letter-spacing: 2px;
  }
  .protivopokazaniya .plashka p img {
    top: -72px;
    width: 82px;
  }
  .section_1 {
    padding: 60px 0px;
  }
  .section_1 h2 {
    margin-bottom: 50px;
  }
  .section_1 #steatoz {
    margin-bottom: 40px;
  }
  .section_1 .numbers_abs {
    width: 60px;
    height: 60px;
  }
  .section_1 .numbers_abs .number {
    font-size: 83px;
    right: -25px;
    top: -50px;
  }
  .section_2 {
    padding: 80px 0px;
    z-index: 1;
  }
  .section_2 h2 {
    margin-bottom: 90px;
  }
  .section_2 .factors_row_1 {
    position: relative;
    z-index: unset;
    top: unset;
  }
  .section_2 .factors_row_2 {
    position: relative;
    z-index: unset;
    top: unset;
  }
  .section_2 .factor_riska {
    margin-bottom: 50px;
  }
  .section_2 .factor_riska:nth-child(2n) .icon_div {
    text-align: right;
  }
  .section_2 .factor_riska:nth-child(2n) .icon_div:before {
    left: unset;
    right: 0;
  }
}
.scroll-container {
  height: 100vh;
  background: black;
}
.speak {
  margin: 70vh 0;
  /*  &:nth-child(4){
  	 margin-bottom: 100vh;
  }
   &:nth-child(5){
  	margin-bottom: 49vh;
    margin-top: 100vh;
  }
     &:nth-child(6){
 				margin-top: 80vh;
  }  */
}
.speak:first-child {
  margin-top: 50vh;
}
.speak[data-scroll] {
  transform: translatey(calc(var(--viewport-y) * 30vh));
  opacity: calc(var(--visible-y));
}
.scroll-sequence__content {
  padding: 4em;
}
.scroll-sequence {
  /*  bottom: 0;*/
  left: 0;
  right: 0;
  z-index: -5;
  width: 300px;
  height: 450px;
  /*  margin-top: 100px;*/
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
/*h1 {
  font-size: 3em;
  width: 60wv;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 2em;
    width: 100%;
  }
}*/
/*:root {
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  color: white;
  text-shadow: 0 0 12px rgba(black, .8);
}*/
body {
  margin: 0;
}
* {
  box-sizing: border-box;
}
.sticky_div {
  position: sticky;
  top: 50px;
}
