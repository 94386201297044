@white: #ffffff;
@black: #000;
@bright_blue: #045F9D;
@blue: #1b588f;
@orange: #EB6D40;
@rgba-color: rgba(red(@white), green(@white), blue(@white), 80%);
@my_container_width: 1160px;
@border_radius: 8px;
@transition_03: 0.3s;
@tablet: 1024px;
@mobile: 525px;
@import "/fonts/Exo2/stylesheet.css";
@import "/fonts/Montserrat/stylesheet.css";

* {
	font-family: Montserrat;
}
.my_container {
	width: @my_container_width;
	margin: auto;
}
.width_100 {
	width: 100%;
}
.buttons_a {
	display: flex;
	flex-wrap: wrap;
	a{
		font-size: 16px;
		color: @black;
		display: flex;
		align-items: center;
		justify-content: center;
		background: @white;
		height: 56px;
		border-radius: @border_radius;
		&:hover {
			background: rgba(white, 0.8);
			color: @black;
			transition: @transition_03;
		}
	}
	.orange_a {
		background: @orange;
		color: @white;
		&:hover{
			background: rgba( @orange, 0.8);
		}
	}
}
h1 {
	font-size: 55px;
	line-height: 66px;
	color: @white;
	font-family: "Exo";
	padding-bottom: 56px;

	span {
		color: @orange;
	}
}
h2 {
	color: @white;
	font-size: 55px;
	line-height: 66px;
	span {
		color: @orange;
	}
}
h3 {
	color: @white;
	font-size: 30px;
	font-family: "Exo";
	span {
		color: @orange;
	}
}
p {
	color: @white;
	font-size: 20px;
}
.wave_div {
	position: absolute;

	bottom: 0px;
	left: 0;
	width: 100%;
}
.plx_bg_div {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
header {
	background: @bright_blue;
	padding-top: 48px;
	padding-bottom: 120px;
	position: relative;
	a {
		font-size: 16px;
		color: @white;
		text-decoration: none;
		&:hover{
			color: @white;
		}
	}
	.plx_bg_div {
		background: url('../img/header_circles.svg') no-repeat center;
		background-size: contain;
	}
	.border_a {
		position: relative;
		padding-bottom: 10px;
		&:after{
			content: '';
			position:absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			height: 10px;
			background: url('../img/border_header.svg') no-repeat;
			background-size: contain;
		}
	}
	.buttons_a {
		margin-top: 45px;
	}
	.title_img {
		.width_100;
		width: calc(@my_container_width/12 * 5);
	}
	.info_row {
		padding: 80px 0px;
	}
}
.protivopokazaniya {

	.plashka {
		height: 79px;
		width: 100%;
		background: #1B6699;
		position: fixed;
		bottom:0px;
		left: 0;
		text-transform: uppercase;
		padding-top: 10px;
		opacity: 1;
		z-index: 10;
		
		p {
			font-family: Exo;
			font-size: 46px;
			line-height: 30px;
			letter-spacing: 14px;
			color: @white;
			position: relative;
			span {
				font-size: 24px;
				letter-spacing: 8px;
				font-family: Exo;
				text-transform: uppercase;
			}
			img {
				position: absolute;
				right: -14px;
   				top: -69px;
			}
		}
	}

}

.section_1 {
	padding: 120px 0px 204px 0px;
	position: relative;
	overflow: hidden;
	background: @blue;
	.plx_bg_div {
		background: url('../img/section_1_plx.svg') no-repeat center;
		background-size: contain;
		z-index: 0;
	}
	h2 {
		margin-bottom: 130px;
	}
	.numbers_abs {
		width: 105px;
		height: 105px;
		position: relative;
		.circle {
			width: 100%;
			height: 100%;
			position: absolute;
			border-radius: 50%;
			opacity: 0.5s;
			background: #1B6699;
		}
		.number {
			font-size: 123px;
			min-width: 64px;
			color: @white;
			position: absolute;
			right: -14px;
			top: -72px;
			z-index: 1;
			font-family: Exo;

		}
	}
}
.section_2 {
	padding: 105px 0px;
	background: @bright_blue;
	position: relative;
	.my_container {
		
		overflow: hidden;
	}
	.overflow_hidden {
		position: relative;
		min-height: 800px;
		overflow: hidden;
	}
	.plx_bg_div {
		background: url('../img/section_2_plx.svg') no-repeat center;
		background-size: contain;
		z-index: 0;
	}
	h2 {
		margin-bottom: 112px;
		padding-top: 20px;
	}
	.factors_row_1 {
		/*position: relative;*/
		position: absolute;
		/*background: @bright_blue;*/
		z-index: 0;
		top: 0;
	}
	.factors_row_2 {
		position: absolute;
		
	}
	.factor_riska {
		/*margin-bottom: 172px;*/
		/*background: @bright_blue;*/
		.p_title {
			font-size: 30px;
			font-family: Exo;
			line-height: 36px;
			min-height: 116px;
			span{
				font-size: 20px;
				font-family: Montserrat;

			}
		}
/*		&:nth-child(n+4) {
			display: none;
		}*/
		.just_text {
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
			opacity: 0.7;
		}
		.icon_div {
			min-height: 198px;
			position: relative;
			img {
				position: relative;
				z-index: 1;
			}
			&:before{
				content: '';
				width: 148px;
				height: 148px;
				position: absolute;
				left: 0;
				top:0;
				border-radius: 50%;
				background:#1B6699 ;
				z-index: 0;
			}
		}

	}
}
.test_sprite {
	background-image: url('../img/sprite_1.png');
	  height: 500px;
    width: 500px;
}
#phaser-example {
	display: flex;
}
@media (max-width:  1440px){
	h2 {
		font-size: 44px;
		line-height: 50px;

	}
	.section_2 h2 {
		margin-bottom: 41px;
	}
}
@media (max-width: @tablet) {
	.my-container {
		width: 900px;
	}

}
@media (max-width: @mobile) {
	h1 {
		font-size: 26px;
		line-height: 32px;
	}
	h2 {
		font-size: 26px;
		line-height: 32px;
	}
	p {
		font-size: 16px;
	}
	.my_container, .my-container {
		width: 315px;
	}
	header {
		padding-bottom: 46px;
		h1 {
			text-align: center;
		}
		.title_img {
			width: 100%;
		}
		.info_row {
			padding: 20px 0px;
		}
/*		.menu_header a{
			display: none;
		}*/
		.buttons_a a {
			margin-bottom: 30px;
		}
	}
	.protivopokazaniya {
		.my_container {
			width: 100%;

		}
		.plashka {
			padding-top: 25px;
			bottom: 0px;
			p {
				line-height: 18px;
				font-size: 11px;
				letter-spacing: 2px;
				span {
					font-size: 11px;
					letter-spacing: 2px;
				}
				img {
					top: -72px;
    				width: 82px;
				}
			}
		}		
	}
	.section_1 {
		padding: 60px 0px;
		h2 {
			margin-bottom: 50px;
		}
		#steatoz {
			margin-bottom: 40px;
		}
		.numbers_abs {
			width: 60px;
			height: 60px;
			.number {
				font-size: 83px;
				right: -25px;
				top: -50px;
			}
		}
	}
	.section_2 {
		padding: 80px 0px;
		z-index: 1;
		h2 {
			margin-bottom: 90px;
		}
		.factors_row_1 {
			position: relative;
			z-index: unset;
			top: unset;
		}
		.factors_row_2 {
			position: relative;
			z-index: unset;
			top: unset;
		}
		.factor_riska {
			margin-bottom: 50px;
			&:nth-child(2n) {
				.icon_div {
					text-align: right;
					&:before {
						left:unset;
						right: 0;
					}
				}
			}
		}
	}

}

.scroll-container {
  height: 100vh;
  background: black;
}
.speak {
  margin: 70vh 0;
  &:first-child {
    margin-top: 50vh;
  }
/*  &:nth-child(4){
  	 margin-bottom: 100vh;
  }
   &:nth-child(5){
  	margin-bottom: 49vh;
    margin-top: 100vh;
  }
     &:nth-child(6){
 				margin-top: 80vh;
  }  */
}

.speak[data-scroll] {
  // /*transition: opacity .3s;*/
  transform: translatey(calc(var(--viewport-y) * 30vh));
  opacity: calc(var(--visible-y));
  
}

.scroll-sequence__content {
  padding: 4em;
}
.scroll-sequence {
 // position: sticky;
  //top: 200px;
/*  bottom: 0;*/
  left: 0;
  right: 0;
  z-index: -5;
  width: 300px;
  height: 450px;
/*  margin-top: 100px;*/
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
/*h1 {
  font-size: 3em;
  width: 60wv;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 2em;
    width: 100%;
  }
}*/

/*:root {
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  color: white;
  text-shadow: 0 0 12px rgba(black, .8);
}*/
body {
  margin: 0;
}

* {
  box-sizing: border-box;
}
.sticky_div {
	position: sticky;
	top: 50px;
}